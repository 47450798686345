import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppStateModule } from '@core/state/app/app-state.module';
import { WebShellModule } from '@shell/web-shell.module';
import { WindowModule } from 'src/vendor/window/window.module';
import { CoreModule } from './@core/core.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    WebShellModule,
    WindowModule,
    
    // NGRX
    AppStateModule
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule {}
