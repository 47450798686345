export function isNullOrUndefined(object: any) {
  return object === null || object === undefined;
}

export function isArray(obj: any): boolean {
  if (!Array.isArray) {
      return Object.prototype.toString.call(obj) === '[object Array]';
  } else {
      return Array.isArray(obj);
  }
}

export function isNonEmptyArray(obj: any): boolean {
  return isArray(obj) && obj.length;
}