import { StateSlice } from '../state-slice';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ITrackingState } from './tracking-state';

export const selectTrackingState = createFeatureSelector<ITrackingState>(StateSlice.TRACKING);

export const getViewedDeliveryOrderId = createSelector(selectTrackingState, (state: ITrackingState) => state.viewedDeliveryOrderId);
export const getDeliveryOrder = createSelector(selectTrackingState, (state: ITrackingState) => state.deliveryOrder);
export const isFetching = createSelector(selectTrackingState, (state: ITrackingState) => state.isFetching);
export const isInvalid = createSelector(selectTrackingState, (state: ITrackingState) => state.isInvalid);
