import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation
} from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'ihd-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'c-not-found'
  }
})
export class NotFoundComponent {
  public readonly imageUrl: string = `${ environment.settings.url }assets/img/ihd-image-empty.png`;
}
    