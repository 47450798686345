import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppEffects } from './app-effects';
import { TrackingStateModule } from '../tracking/tracking-state.module';
import { RouterStateModule } from '../router/router-state.module';
import { initialAppState } from './app-state';
import { SessionStateModule } from '../session/session-state.module';

@NgModule({
  imports: [
    EffectsModule.forRoot([
      AppEffects
    ]),
    StoreModule.forRoot({}, {
      initialState: initialAppState,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false
      }
    }),
    TrackingStateModule,
    RouterStateModule,
    SessionStateModule
  ],
  declarations: []
})
export class AppStateModule {
}