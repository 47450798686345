import { DeliveryOrder } from '@core/models/dispatch/delivery-order';

export interface ITrackingState {
    isFetching: boolean;
    deliveryOrder: DeliveryOrder;
    viewedDeliveryOrderId: string;
    isInvalid: boolean;
  }
  
  export function initialTrackingState(): ITrackingState {
    return {
      isFetching: false,
      deliveryOrder: null,
      viewedDeliveryOrderId: null,
      isInvalid: false
    };
  }