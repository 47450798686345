import {
    SESSION_LOCALE_SET
  } from '../action-types';
  import { ISessionState, initialSessionState } from './session-state';
  
  export function reducer(state: ISessionState = initialSessionState(), action: any): ISessionState {
    switch (action.type) {
      case SESSION_LOCALE_SET:
        return {
          ...state,
          locale: action.payload.locale
        };
        
      default:
        return state;
    }
  }
  