import { NgModule, Inject } from '@angular/core';
import { StoreModule, ActionReducer, Store } from '@ngrx/store';
import { StateSlice } from '../state-slice';
import { reducer } from './router-reducer';
import { initialRouterState, IAppRouterState } from './router-state';
import { combineReducers } from '../utils/state-utils';
import { EffectsModule } from '@ngrx/effects';
import { RouterEffects } from './router-effects';
import { Router, NavigationStart, RouterStateSnapshot, NavigationEnd, NavigationError, ActivationEnd } from '@angular/router';
import {
  ROUTER_NAVIGATION,
  ROUTER_ERROR
} from '../action-types';
import { withLatestFrom } from 'rxjs/operators';
import { getRouterState } from './router-selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(StateSlice.ROUTER, (reducer as ActionReducer<any, any>), {
      initialState: initialRouterState,
      reducerFactory: combineReducers
    }),
    EffectsModule.forFeature([RouterEffects])
  ],
  declarations: []
})
export class RouterStateModule {

  private dispatchTriggeredByRouter: boolean;
  private navigationTriggeredByDispatch: boolean;
  private routerState: any;
  private fromUrl: string;
  private fromQueryParams: any;
  private fromParams: any;
  private params: any;

  constructor(@Inject(Router) private router: Router,
              @Inject(Store) private store$: Store<IAppRouterState>) {

    this.router.events
      .pipe(withLatestFrom(this.store$.select(getRouterState)))
      .subscribe(([event, routerState]: [any, IAppRouterState]) => {

        if (event instanceof NavigationStart) {
          const snapshot: RouterStateSnapshot = this.router.routerState.snapshot;
          this.fromUrl = snapshot.url;
          this.fromParams = routerState.params;
          this.fromQueryParams = routerState.queryParams;
        }

        if (event instanceof ActivationEnd && (Object.keys(event.snapshot.params).length)) {

          // Patch parameters in router state
          this.params = event.snapshot?.params || {};
        }

        if (event instanceof NavigationEnd) {
          const snapshot: RouterStateSnapshot = this.router.routerState.snapshot;

          try {
            this.store$.dispatch({ type: ROUTER_NAVIGATION, payload: {
              routerState: {
                url: snapshot.url,
                params: this.params,
                queryParams: snapshot.root.queryParams,
                fromState: {
                  url: this.fromUrl,
                  params: this.fromParams
                }
              }
            }});

          } finally {
            this.dispatchTriggeredByRouter = false;
            this.navigationTriggeredByDispatch = false;
          }
        }

        if (event instanceof NavigationError) {
          // Present error to user
          console.error(event.error);

          try {

            this.store$.dispatch({ type: ROUTER_ERROR, payload: { error: event.error }});

          } catch (error) {
            console.error(error);
          }
        }

        
      });
  }
}
