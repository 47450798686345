import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NotFoundComponentModule } from '@components/not-found/not-found-component.module';
import { NotFoundComponent } from '@components/not-found/not-found.component';
import { ROUTER_UTILS } from '@core/utils/router.utils';

@NgModule({
  imports: [
    CommonModule,
    NotFoundComponentModule,
    RouterModule.forChild([
      {
        path: ROUTER_UTILS.config.errorResponse.notFound,
        component: NotFoundComponent
      },
    ]),
  ],
})
export class NotFoundModule {}
