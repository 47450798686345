import { NgModule } from '@angular/core';
import { WINDOW_PROVIDERS } from './window.component';

@NgModule({
 imports: [],
 exports: [],
 declarations: [],
 providers: [ WINDOW_PROVIDERS],
})
export class WindowModule { }
