import { ROUTER_NAVIGATION, ROUTER_ERROR, ROUTER_CANCEL, ROUTER_STATE_SET } from '../action-types';

export function reducer(state: any, action: { type: any; payload: { routerState: any; }; }) {
  switch (action.type) {
      case ROUTER_NAVIGATION:
      case ROUTER_ERROR:
      case ROUTER_CANCEL:
      case ROUTER_STATE_SET:
          return { ...action.payload.routerState };
      default:
          return state;
  }
}