import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { isNullOrUndefined, isArray } from '../../utils/object';

@Injectable({ providedIn: 'root' })
export class HttpClientService {

    protected apiEndpoint: string;

    constructor(protected http: HttpClient) {
        this.initApiEndpoint();
    }

    /**
     * Override this method if the data service needs to use a different endpoint
     */
    protected initApiEndpoint() {
        this.apiEndpoint = environment.api.endpoint;
    }

    /**
     * GET request.
     * @param url
     * @param options
     * @returns {Observable<any>}
     */
    protected get(url: string, options?: any, bridgeAppEndPoint?: boolean): Observable<any> {
        if (!bridgeAppEndPoint) {
            url = this.apiEndpoint + url;
        }

        return this.http.get(url, options)
          .pipe(
            catchError((error) => this.parseError(error))
          );
    }

    /**
     * Get app resource file
     * @protected
     * @param {string} url
     * @returns
     * @memberof BaseHttpService
     */
    protected getResource(url: string, options?: any) {
        return this.http.get(url, options)
          .pipe(
            catchError((error) => this.parseError(error))
          );
    }

    /**
     * POST request.
     * @param url
     * @param body
     * @param options
     * @returns {Observable<any>}
     */
    protected post(url: string, body?: any, options?: any, bridgeAppEndPoint?: boolean): Observable<any> {
        if (!bridgeAppEndPoint) {
            url = this.apiEndpoint + url;
        }

        return this.http.post(url, body, options)
          .pipe(
            catchError((error) => this.parseError(error))
          );
    }

    protected postResource(url: string, body?: any, options?: any): Observable<any> {
        return this.http.post(url, body, options)
          .pipe(
            catchError((error) => this.parseError(error))
          );
    }

    /**
     * PUT request.
     * @param url
     * @param body
     * @param options
     * @returns {Observable<any>}
     */
    protected put(url: string, body: any, options?: any, bridgeAppEndPoint?: boolean): Observable<any> {
        if (!bridgeAppEndPoint) {
            url = this.apiEndpoint + url;
        }

        return this.http.put(url, body, options)
          .pipe(
            catchError((error) => this.parseError(error))
          );
    }

    /**
     * DELETE request.
     *
     * @protected
     * @param {string} url
     * @param {RequestOptionsArgs} [options]
     * @returns {Observable<any>}
     * @memberof BaseHttpService
     */
    protected delete(url: string, body?: any, options?: any, bridgeAppEndPoint?: boolean): Observable<any> {
        options = options || {};

        if (!bridgeAppEndPoint) {
            url = this.apiEndpoint + url;
        }

        options.headers = options.headers ?
          options.headers.append('Content-Type', 'application/json') :
          new HttpHeaders().set('Content-Type', 'application/json');

        if (body) {
            options.body = body;
        }

        return this.http.delete(url, options)
          .pipe(
            catchError((error) => this.parseError(error))
          );
    }

    /**
     * Filters the request based on whitelist fields.
     * @param whitelist
     * @param obj
     * @returns {{}}
     */
    protected static validate(whitelist: string[], obj: any): any {
        const res: any = {};
        whitelist.forEach((fieldName) => {

            if (Object.prototype.hasOwnProperty.call(obj, fieldName)) {
                res[fieldName] = obj[fieldName];
            }
        });

        return res;
    }

    /**
     * Filters the list of objects based on whitelist fields.
     * @param whitelist
     * @param list
     * @returns {{}}
     */
    protected static validateList(whitelist: string[], list: any[]): any {
        const res: any = [];
        list.forEach((listItem) => {
            res.push(HttpClientService.validate(whitelist, listItem));
        });

        return res;
    }

    /**
     * Converts an object key/values to a query string-pipes.
     * @param request
     * @returns {string}
     */
    protected toQueryParams(request?: any): string {
        let res = '';
        if (request) {
            Object.keys(request).forEach((key) => {
                if (isArray(request[key])) {
                    // ReportDataset is defining a toString method which is doing the trick behind `join` in reports query building
                    // Any class could define toString method which would be called on `.join` for custom behaviour of joining of keys
                    res += key + '=' + request[key].join(',') + '&';
                } else if (!isNullOrUndefined(request[key])) {
                    res += key + '=' + request[key] + '&';
                }
            });
        }

        if (res.length > 0) {
            res = '?' + res;
        }
        return res;
    }

    /**
     * The parsing is needed possibly because of a bug in Angular at the moment where the error
     * response isn't parsed
     *
     * @param errorResponse
     * @returns {Observable<any>}
     */
    private parseError(errorResponse: any): Observable<any> {
        if (typeof errorResponse.error === 'string') {
            try {
                errorResponse.error = JSON.parse(errorResponse.error);
            } catch(e) {
                // if the parse fails then simply return unknown error
                console.error('error parse failed', errorResponse);
                errorResponse.error = {
                    error: 'Errors.Unknown'
                };
            }

        }
        return throwError(() => new Error(errorResponse));
    }
}
