export const BACKEND_URLS = Object({
  "PROD": "https://go.opensimsim.com/v3",
  "DEMO": "https://bells-dem.opensimsim.com/v3",
  "LOCAL": "https://pg-2.opensimsim.cloud/v3",
  "PG-1": "https://pg-1.opensimsim.cloud/v3",
  "PG-2": "https://pg-2.opensimsim.cloud/v3",
  "PG-3": "https://pg-3.opensimsim.cloud/v3",
  "PG-4": "https://pg-4.opensimsim.cloud/v3",
  "PG-5": "https://pg-5.opensimsim.cloud/v3",
  "PG-6": "https://pg-6.opensimsim.cloud/v3"
});
