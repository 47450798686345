import {
  TRACKING_DELIVERY_ORDER_FETCH,
  TRACKING_DELIVERY_ORDER_FETCHING_SET,
  TRACKING_DELIVERY_ORDER_VIEWED_SET,
  TRACKING_DELIVERY_ORDER_SET,
  TRACKING_DELIVERY_ORDER_INVALID_SET
} from '../action-types';
import { ITrackingState, initialTrackingState } from './tracking-state';

export function reducer(state: ITrackingState = initialTrackingState(), action: any): ITrackingState {
  switch (action.type) {
    case TRACKING_DELIVERY_ORDER_FETCH:
      return {
        ...state,
        isFetching: true
      };

    case TRACKING_DELIVERY_ORDER_FETCHING_SET:
      return {
        ...state,
        isFetching: action.payload.value
      };

    case TRACKING_DELIVERY_ORDER_VIEWED_SET:
      return {
        ...state,
        viewedDeliveryOrderId: action.payload.deliveryOrderId
      };

    case TRACKING_DELIVERY_ORDER_SET:
      return {
        ...state,
        deliveryOrder: action.payload.deliveryOrder
      };

    case TRACKING_DELIVERY_ORDER_INVALID_SET:
      return {
        ...state,
        isInvalid: action.payload.isInvalid
      }

    default:
      return state;
  }
}
