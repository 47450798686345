import { NgModule } from '@angular/core';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { StateSlice } from '../state-slice';
import { combineReducers } from '../utils/state-utils';
import { initialTrackingState } from './tracking-state';
import { reducer } from './tracking-reducer';
import { EffectsModule } from '@ngrx/effects';
import { TrackingEffects } from './tracking-effects';
import { DispatchDataService } from '@core/data/api/dispatch/dispatch-data.service';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
    imports: [
      HttpClientModule,
      StoreModule.forFeature(StateSlice.TRACKING, (reducer as ActionReducer<any, any>), {
        initialState: initialTrackingState,
        reducerFactory: combineReducers
      }),
      EffectsModule.forFeature([ TrackingEffects ])
    ],
    declarations: [],
    providers: [
      DispatchDataService
    ]
  })
  export class TrackingStateModule {}