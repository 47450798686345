import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ISessionState } from './session-state';

@Injectable()
export class SessionEffects {

  constructor(private store$: Store<ISessionState>,
              private actions$: Actions) {
  }
}
