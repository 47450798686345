// Generic Empty Action Type
export const NO_ACTION =                                'NO_ACTION';

// Generic Router Action Type;
export const ROUTER_CANCEL                                = 'ROUTER_CANCEL';
export const ROUTER_ERROR                                 = 'ROUTER_ERROR';
export const ROUTER_GO                                    = 'ROUTER_GO';
export const ROUTER_NAVIGATION                            = 'ROUTER_NAVIGATION';
export const ROUTER_NAVIGATION_SUCCESS                    = 'ROUTER_NAVIGATION_SUCCESS';
export const ROUTER_STATE_SET                             = 'ROUTER_STATE_SET';

// Tracking feature
export const TRACKING_DELIVERY_ORDER_INVALID_SET          = 'TRACKING_DELIVERY_ORDER_INVALID_SET';
export const TRACKING_DELIVERY_ORDER_SET                  = 'TRACKING_DELIVERY_ORDER_SET';
export const TRACKING_DELIVERY_ORDER_VIEWED_SET           = 'TRACKING_DELIVERY_ORDER_VIEWED_SET';
export const TRACKING_DELIVERY_ORDER_FETCH                = 'TRACKING_DELIVERY_ORDER_FETCH';
export const TRACKING_DELIVERY_ORDER_FETCHING_SET         = 'TRACKING_DELIVERY_ORDER_FETCHING_SET';

// Session
export const SESSION_LOCALE_SET                           = 'SESSION_LOCALE_SET';