import { NgModule } from '@angular/core';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { StateSlice } from '../state-slice';
import { combineReducers } from '../utils/state-utils';
import { initialSessionState } from './session-state';
import { reducer } from './session-reducer';
import { EffectsModule } from '@ngrx/effects';
import { SessionEffects } from './session-effects';

@NgModule({
    imports: [
      StoreModule.forFeature(StateSlice.SESSION, (reducer as ActionReducer<any, any>), {
        initialState: initialSessionState,
        reducerFactory: combineReducers
      }),
      EffectsModule.forFeature([ SessionEffects ])
    ]
  })
  export class SessionStateModule {}