import { STORE_DATA, STORE_LOCALE } from "@core/localStorage/local-storage";
import { environment } from "@environments/environment";

export interface ISessionState {
  locale: string
}

const initialState: ISessionState = {
  locale: null
}

export function initialSessionState() {
  const w: any = window;
  let session = { ...initialState };
  const defaultLocale: string = 'en-US';
  let locale = defaultLocale;

  try {
    session = JSON.parse(w.localStorage.getItem(`${environment.settings.localStoragePrefix}.${ STORE_DATA }`)) || { ...initialState };
  } catch (error) {
    console.error(error);
  }

  try {
      locale = JSON.parse(w.localStorage.getItem(`${environment.settings.localStoragePrefix}.${ STORE_LOCALE }`)) || defaultLocale;
  } catch (error) {
    console.error(error);
  }
  
  session['locale'] = locale;

  return session;
}