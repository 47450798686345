export interface IAppState {
  [key: string]: any   
}

const initialState: IAppState = {

}

export function initialAppState() {
  return { ...initialState };
}

