import { Injectable } from '@angular/core';
import { ITrackingState } from './tracking-state';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { DispatchDataService } from '@core/data/api/dispatch/dispatch-data.service';
import { FetchDeliveryOrderAction, SetDeliveryOrderAction, SetIsFetchingDeliveryOrderAction, SetIsInvalidDeliveryOrderAction, SetViewedDeliveryOrderIdAction }from './tracking-actions'; 
import { catchError, finalize, mergeMap, switchMap, withLatestFrom } from 'rxjs';
import { getViewedDeliveryOrderId } from './tracking-selectors';
import { ROUTER_NAVIGATION } from '../action-types';
import { getRouterState } from '../router/router-selectors';
import { IAppRouterState } from '../router/router-state';
import { ROUTE_HOME } from '@core/router/app-routes';
import { DeliveryOrder } from '@core/models/dispatch/delivery-order';
import { DeliveryOrderStatus } from '@core/models/dispatch/delivery-order-status';

@Injectable()
export class TrackingEffects {

  constructor(private store$: Store<ITrackingState>,
              private actions$: Actions,
              private dispatchDataService: DispatchDataService) {
  }

  fetchDeliveryOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FetchDeliveryOrderAction.type),
      withLatestFrom(
        this.store$.select(getViewedDeliveryOrderId)
      ),
      switchMap(([_action, deliveryOrderId]: [Action, string]) => {

        return this.dispatchDataService.fetchDispatchOrder(deliveryOrderId)
          .pipe(
            switchMap((response: any) => {

              const deliveryOrder: DeliveryOrder = response.order;

              return [
                deliveryOrder.status === DeliveryOrderStatus.REJECTED
                ? SetIsInvalidDeliveryOrderAction({ payload: { isInvalid: true }})
                : SetDeliveryOrderAction({
                  payload: {
                    deliveryOrder: response.order
                  }
                })
              ];
            }),
            catchError(() => [
              SetIsInvalidDeliveryOrderAction({ payload: { isInvalid: true }})
            ]),
            finalize(() => {
              this.store$.dispatch(SetIsFetchingDeliveryOrderAction({ payload: { value: false }}));
            })
          );
      })
    )
  });

  routeTracking$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      withLatestFrom(
        this.store$.select(getRouterState)
      ),
      mergeMap(([_action, routerState]: [Action, IAppRouterState]) => {
        const actions: Action[] = [];
        
        const deliveryOrderId = routerState?.queryParams?.id;
        const rootUrl = routerState.url && routerState.url.split('?')[0];

        if (rootUrl === ROUTE_HOME) {
          if (!deliveryOrderId) {
            actions.push(SetIsInvalidDeliveryOrderAction({ payload: { isInvalid: true }}));
          } else {
            actions.push(SetViewedDeliveryOrderIdAction({ payload: { deliveryOrderId }}));
            actions.push(FetchDeliveryOrderAction());
          }
        }

        return actions;
      })
    )
  })
}