<div class="container">
  <div class="row">
    <div class="offset-lg-2 col-lg-8">
      <div class="c-card c-card--landing-page">
        <div class="c-card__body c-card__body--minimal">
          <div class="c-not-found__content">
            <img [src]="imageUrl" class="c-not-found__image">
            <div class="c-card__title">
              Nothing to see here
            </div>
            <div>This link has either expired or does not exist</div>
          </div>
        </div>       
      </div>
    </div>
  </div>
</div>
