export enum DeliveryOrderStatus {
  ARRIVED_AT_DROPOFF                            = 'arrived_at_dropoff',
  ARRIVED_AT_PICKPUP                            = 'arrived_at_pickup',
  ARRIVED_AT_RETURN                             = 'arrived_at_return',
  ASSIGNED                                      = 'assigned',
  ACCEPTED                                      = 'accepted',
  CANCELLED                                     = 'canceled',
  DELIVERED                                     = 'delivered',
  DROPOFF                                       = 'dropoff',
  ENROUTE_TO_DROPOFF                            = 'enroute_to_dropoff',
  ENROUTE_TO_PICKUP                             = 'enroute_to_pickup',
  ENROUTE_TO_RETURN                             = 'enroute_to_return',
  NEW                                           = 'new',
  SCHEDULED                                     = 'scheduled',
  PICKUP                                        = 'pickup',
  PICKED_UP                                     = 'picked_up',
  REJECTED                                      = 'rejected',
  RETURNED                                      = 'returned',
  RETURNING                                     = 'returning',
  UNASSIGNED                                    = 'unassigned'
}