import { IWhiteLabelContentSchema } from './content.schema';

export const WHITE_LABEL_CONTENT: { [key: string]: IWhiteLabelContentSchema} = {

    'track-white-label.inhousedelivery.cloud': {
        help: {
            default: `
                <div class="c-delivery-order-help__subtitle">
                    What's the status of my order?
                </div>
            
                <p>
                    Lorem ipsum blah blah
                </p>
            
                <p class="mb-4">
                    Please note the restaurant is unable to supply further information about the delivery than is displayed here. 
                </p>
            
                <div class="c-delivery-order-help__subtitle">
                    I want to change or cancel my order
                </div>
                
                <p>
                    You are unable to change or cancel your order from this page. Please contact the restaurant if required.
                </p>`,
            pendingOrRejected: ``,
            pickingUp: `
                <div class="c-delivery-order-help__subtitle">
                    Who is my driver?
                </div>
                
                <p class="mb-4">
                    asdfasdfas fasdfasdfadfs
                </p>
                <div class="c-delivery-order-help__subtitle">
                    I need to contact the driver
                </div>
                
                <p class="mb-4">
                    asdfsad fasdfasfasfasfdsdfdafsdaf
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I want to change or cancel my order
                </div>
                
                <p>
                    You are unable to change or cancel your order as the driver is on their way to pick up your food. Please contact the restaurant if required.
                </p>
            `,
            droppingOff: `
                <div class="c-delivery-order-help__subtitle">
                    Who is my driver?
                </div>
                
                <p class="mb-4">
                    asdfasdfpppppppppp p p p p p p p p  p p p p p 
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I need to contact the driver
                </div>
                
                <p class="mb-4">
                    You may contact your driver by clicking the button below. Please note this phone number is only valid during the duration of the delivery.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I want to change or cancel my order
                </div>
                
                <p>
                    You are unable to change or cancel your order as the driver is on their way with your order.
                </p>
            `,
            delivered: `
                <div class="c-delivery-order-help__subtitle">
                    dasdfs saf dfsaf saf sf 
                </div>
                
                <p>
                    asdf sa dfsadfasdfsaf f af f
                </p>

                <p class="mb-4">
                    werwerwe rwerw e rw wr er wer 
                </p>

                <div class="c-delivery-order-help__subtitle">
                    poipoipoioip oiopipo po ipop ory
                </div>

                <p>
                    Food arrived cold? Missing an item? Received the wrong order? Please contact the restaurant for support.
                </p>
            `,
            cancelled: `
                <div class="c-delivery-order-help__subtitle">
                    kljlkjhkjlhkljhlj hjkhlkjhlkh
                </div>

                <p class="mb-4">
                    Please contact the restaurant for more information on why your order has been canceled.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    ljk;hljkh jkhjlh jhlkh ljkh 
                </div>

                <p>
                    lkjhlkjhdsjkflahj sldkjfas dklfjhsa lfkajh fkajhd
                </p>
            `,
            returned: `
                <div class="c-delivery-order-help__subtitle">
                    asdfs asdfasd fasf sa fs
                </div>

                <p class="mb-4">
                     ewq rwqe rwqe rwe r r r wr qr qwr 
                </p>

                <div class="c-delivery-order-help__subtitle">
                    rty rty rty rt try rty rt 
                </div>

                <p>
                    sfdfdsd s sd fsf f fssd sfsdf df dg dg dgd
                </p>
            `,
        },
        poweredBy: `For any questions or issues, please contact our support line at <a>(555) 1212</a>`,
        title: 'Curbngo',
        favicon: './assets/white-label/favicons/favicon-curbngo.png'
    },
    'track.mydelivereats.com': {
        help: {
            default: `
                <div class="c-delivery-order-help__subtitle">
                    What's the status of my order?
                </div>
            
                <p>
                    Your order has been accepted by the restaurant and is currently being prepared. We’ll update the tracking page as soon as a driver has been assigned and an ETA is available.
                </p>
            
                <p class="mb-4">
                    Please note the restaurant is unable to supply further information about the delivery than is displayed here. 
                </p>
            
                <div class="c-delivery-order-help__subtitle">
                    I want to change or cancel my order
                </div>
                
                <p>
                    You are unable to change or cancel your order from this page. Please contact our support team at <a class="c-delivery-order__powered-by-link" href="tel:+17029410921">&nbsp;702-941-0921</a>
                </p>`,
            pendingOrRejected: ``,
            pickingUp: `
                <div class="c-delivery-order-help__subtitle">
                    Who is my driver?
                </div>
                
                <p class="mb-4">
                    We work with a number of trusted delivery service partners. The driver delivering your order may not be from the same company where you placed your order. Regardless, your food will still taste just as good!
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I need to contact the driver
                </div>
                
                <p class="mb-4">
                    You may contact your driver directly by clicking the button below. Please note this phone number is only valid during the duration of the delivery.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I want to change or cancel my order
                </div>
                
                <p>
                    You are unable to change or cancel your order as the driver is on their way to pick up your food. Please contact our support team at <a class="c-delivery-order__powered-by-link" href="tel:+17029410921">&nbsp;702-941-0921</a> for further help.
                </p>
            `,
            droppingOff: `
                <div class="c-delivery-order-help__subtitle">
                    Who is my driver?
                </div>
                
                <p class="mb-4">
                    We work with a number of trusted delivery service partners. The driver delivering your order may not be from the same company where you placed your order. Regardless, your food will still taste just as good!
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I need to contact the driver
                </div>
                
                <p class="mb-4">
                    You may contact your driver directly by clicking the button below. Please note this phone number is only valid during the duration of the delivery.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I want to change or cancel my order
                </div>
                
                <p>
                    You are unable to change or cancel your order as the driver is on their way with your order. Please contact our support team at <a class="c-delivery-order__powered-by-link" href="tel:+17029410921">&nbsp;702-941-0921</a> for further help.
                </p>
            `,
            delivered: `
                <div class="c-delivery-order-help__subtitle">
                    I haven't received my order
                </div>
                
                <p>
                    Your order may have been left in a safe place by the driver, such as the front porch or doorstep. If your driver took a photo of where they left it, you will see this on the order tracking page.
                </p>

                <p class="mb-4">
                    If you are still unable to find your order, please contact our support team at <a class="c-delivery-order__powered-by-link" href="tel:+17029410921">&nbsp;702-941-0921</a> for further help.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    There’s a problem with my delivery
                </div>

                <p>
                    Food arrived cold? Missing an item? Received the wrong order? Please contact our support team at <a class="c-delivery-order__powered-by-link" href="tel:+17029410921">&nbsp;702-941-0921</a> for further help.
                </p>
            `,
            cancelled: `
                <div class="c-delivery-order-help__subtitle">
                    Why was my order canceled?
                </div>

                <p class="mb-4">
                    Please contact our support team at <a class="c-delivery-order__powered-by-link" href="tel:+17029410921">&nbsp;702-941-0921</a> for further help.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    How can I get a refund for my order?
                </div>

                <p>
                    Please contact our support team at <a class="c-delivery-order__powered-by-link" href="tel:+17029410921">&nbsp;702-941-0921</a> for further help.
                </p>
            `,
            returned: `
                <div class="c-delivery-order-help__subtitle">
                    Why is my order being returned?
                </div>

                <p>
                    Normally, orders are returned because the driver is unable to contact you and / or find a safe place to leave the order. Some orders are unable to be left as they require an ID check (for example, alcohol delivery). Drivers will make every effort to contact you before they return your order.
                </p>

                <p class="mb-4">
                    If you see your order is being returned, please contact our support team at <a class="c-delivery-order__powered-by-link" href="tel:+17029410921">&nbsp;702-941-0921</a> for further help.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    Can my driver return my order to me?
                </div>

                <p>
                    Once a driver has started their return to the restaurant, they are unable to return with your order. Please contact our support team at <a class="c-delivery-order__powered-by-link" href="tel:+17029410921">&nbsp;702-941-0921</a> for further help.
                </p>
            `,
        },
        poweredBy: ``,
        title: 'My Deliver Eats'
    },
    'track.foodiestakeout.com': {
        help: {
            default: `
                <div class="c-delivery-order-help__subtitle">
                    What's the status of my order?
                </div>
            
                <p>
                    Your order has been accepted by the restaurant and is currently being prepared. We’ll update the tracking page as soon as a driver has been assigned and an ETA is available.
                </p>
            
                <p class="mb-4">
                    Please note the restaurant is unable to supply further information about the delivery than is displayed here. 
                </p>
            
                <div class="c-delivery-order-help__subtitle">
                    I want to change or cancel my order
                </div>
                
                <p>
                    You are unable to change or cancel your order from this page. Please contact our support team at <a class="c-delivery-order__powered-by-link" href="tel:+18503918580">&nbsp;850-391-8580</a>
                </p>`,
            pendingOrRejected: ``,
            pickingUp: `
                <div class="c-delivery-order-help__subtitle">
                    Who is my driver?
                </div>
                
                <p class="mb-4">
                    We work with a number of trusted delivery service partners. The driver delivering your order may not be from the same company where you placed your order. Regardless, your food will still taste just as good!
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I need to contact the driver
                </div>
                
                <p class="mb-4">
                    You may contact your driver directly by clicking the button below. Please note this phone number is only valid during the duration of the delivery.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I want to change or cancel my order
                </div>
                
                <p>
                    You are unable to change or cancel your order as the driver is on their way to pick up your food. Please contact our support team at <a class="c-delivery-order__powered-by-link" href="tel:+18503918580">&nbsp;850-391-8580</a> for further help.
                </p>
            `,
            droppingOff: `
                <div class="c-delivery-order-help__subtitle">
                    Who is my driver?
                </div>
                
                <p class="mb-4">
                    We work with a number of trusted delivery service partners. The driver delivering your order may not be from the same company where you placed your order. Regardless, your food will still taste just as good!
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I need to contact the driver
                </div>
                
                <p class="mb-4">
                    You may contact your driver directly by clicking the button below. Please note this phone number is only valid during the duration of the delivery.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I want to change or cancel my order
                </div>
                
                <p>
                    You are unable to change or cancel your order as the driver is on their way with your order. Please contact our support team at <a class="c-delivery-order__powered-by-link" href="tel:+18503918580">&nbsp;850-391-8580</a> for further help.
                </p>
            `,
            delivered: `
                <div class="c-delivery-order-help__subtitle">
                    I haven't received my order
                </div>
                
                <p>
                    Your order may have been left in a safe place by the driver, such as the front porch or doorstep. If your driver took a photo of where they left it, you will see this on the order tracking page.
                </p>

                <p class="mb-4">
                    If you are still unable to find your order, please contact our support team at <a class="c-delivery-order__powered-by-link" href="tel:+18503918580">&nbsp;850-391-8580</a> for further help.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    There’s a problem with my delivery
                </div>

                <p>
                    Food arrived cold? Missing an item? Received the wrong order? Please contact our support team at <a class="c-delivery-order__powered-by-link" href="tel:+18503918580">&nbsp;850-391-8580</a> for further help.
                </p>
            `,
            cancelled: `
                <div class="c-delivery-order-help__subtitle">
                    Why was my order canceled?
                </div>

                <p class="mb-4">
                    Please contact our support team at <a class="c-delivery-order__powered-by-link" href="tel:+18503918580">&nbsp;850-391-8580</a> for further help.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    How can I get a refund for my order?
                </div>

                <p>
                    Please contact our support team at <a class="c-delivery-order__powered-by-link" href="tel:+18503918580">&nbsp;850-391-8580</a> for further help.
                </p>
            `,
            returned: `
                <div class="c-delivery-order-help__subtitle">
                    Why is my order being returned?
                </div>

                <p>
                    Normally, orders are returned because the driver is unable to contact you and / or find a safe place to leave the order. Some orders are unable to be left as they require an ID check (for example, alcohol delivery). Drivers will make every effort to contact you before they return your order.
                </p>

                <p class="mb-4">
                    If you see your order is being returned, please contact our support team at <a class="c-delivery-order__powered-by-link" href="tel:+18503918580">&nbsp;850-391-8580</a> for further help.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    Can my driver return my order to me?
                </div>

                <p>
                    Once a driver has started their return to the restaurant, they are unable to return with your order. Please contact our support team at <a class="c-delivery-order__powered-by-link" href="tel:+18503918580">&nbsp;850-391-8580</a> for further help.
                </p>
            `,
        },
        poweredBy: `
            Powered by <a class="c-delivery-order__powered-by-link" href="https://app.foodiestakeout.com/" target="_blank">Foodies Takeout & Delivery</a>
        `,
        title: 'Foodies Takeout',
        favicon: './assets/white-label/favicons/favicon-foodiestakeout.png'
    },
    'track.fooduyu.com': {
        help: {
            default: `
                <div class="c-delivery-order-help__subtitle">
                    What's the status of my order?
                </div>
            
                <p>
                    Your order has been accepted by the restaurant and is currently being prepared. We’ll update the tracking page as soon as a driver has been assigned and an ETA is available.
                </p>
            
                <p class="mb-4">
                    Please note the restaurant is unable to supply further information about the delivery than is displayed here. 
                </p>
            
                <div class="c-delivery-order-help__subtitle">
                    I want to change or cancel my order
                </div>
                
                <p>
                    You are unable to change or cancel your order from this page. Please contact the restaurant for further help.
                </p>`,
            pendingOrRejected: ``,
            pickingUp: `
                <div class="c-delivery-order-help__subtitle">
                    Who is my driver?
                </div>
                
                <p class="mb-4">
                    We work with a number of trusted delivery service partners. The driver delivering your order may not be from the same company where you placed your order. Regardless, your food will still taste just as good!
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I need to contact the driver
                </div>
                
                <p class="mb-4">
                    You may contact your driver directly by clicking the button below. Please note this phone number is only valid during the duration of the delivery.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I want to change or cancel my order
                </div>
                
                <p>
                    You are unable to change or cancel your order as the driver is on their way to pick up your food. Please contact the restaurant for further help.
                </p>
            `,
            droppingOff: `
                <div class="c-delivery-order-help__subtitle">
                    Who is my driver?
                </div>
                
                <p class="mb-4">
                    We work with a number of trusted delivery service partners. The driver delivering your order may not be from the same company where you placed your order. Regardless, your food will still taste just as good!
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I need to contact the driver
                </div>
                
                <p class="mb-4">
                    You may contact your driver directly by clicking the button below. Please note this phone number is only valid during the duration of the delivery.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I want to change or cancel my order
                </div>
                
                <p>
                    You are unable to change or cancel your order as the driver is on their way with your order. Please contact the restaurant for further help.
                </p>
            `,
            delivered: `
                <div class="c-delivery-order-help__subtitle">
                    I haven't received my order
                </div>
                
                <p>
                    Your order may have been left in a safe place by the driver, such as the front porch or doorstep. If your driver took a photo of where they left it, you will see this on the order tracking page.
                </p>

                <p class="mb-4">
                    If you are still unable to find your order, please contact the restaurant for further help.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    There’s a problem with my delivery
                </div>

                <p>
                    Food arrived cold? Missing an item? Received the wrong order? Please contact the restaurant for further help.
                </p>
            `,
            cancelled: `
                <div class="c-delivery-order-help__subtitle">
                    Why was my order canceled?
                </div>

                <p class="mb-4">
                    Please contact the restaurant for more information on why your order has been canceled.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    How can I get a refund for my order?
                </div>

                <p>
                    Please contact the provider where you made your order.
                </p>
            `,
            returned: `
                <div class="c-delivery-order-help__subtitle">
                    Why is my order being returned?
                </div>

                <p>
                    Normally, orders are returned because the driver is unable to contact you and / or find a safe place to leave the order. Some orders are unable to be left as they require an ID check (for example, alcohol delivery). Drivers will make every effort to contact you before they return your order.
                </p>

                <p class="mb-4">
                    If you see your order is being returned, try calling the driver using the button below. You can also contact the restaurant for further help.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    Can my driver return my order to me?
                </div>

                <p>
                    Once a driver has started their return to the restaurant, they are unable to return with your order. Please contact the restaurant for further help.
                </p>
            `,
        },
        poweredBy: `
                Powered by <a class="c-delivery-order__powered-by-link" href="https://fooduyu.com/" target="_blank">Fooduyu Delivery Service</a>
        `,
        title: 'Fooduyu'
    },
    'track.curbngo.com': {
        help: {
            default: `
                <div class="c-delivery-order-help__subtitle">
                    What's the status of my order?
                </div>
            
                <p>
                    Your order has been accepted by the restaurant and is currently being prepared. We’ll update the tracking page as soon as a driver has been assigned and an ETA is available.
                </p>
            
                <p class="mb-4">
                    Please note the restaurant is unable to supply further information about the delivery than is displayed here. 
                </p>
            
                <div class="c-delivery-order-help__subtitle">
                    I want to change or cancel my order
                </div>
                
                <p>
                    You are unable to change or cancel your order from this page. Please contact the restaurant for further help.
                </p>`,
            pendingOrRejected: ``,
            pickingUp: `
                <div class="c-delivery-order-help__subtitle">
                    Who is my driver?
                </div>
                
                <p class="mb-4">
                    We work with a number of trusted delivery service partners. The driver delivering your order may not be from the same company where you placed your order. Regardless, your food will still taste just as good!
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I need to contact the driver
                </div>
                
                <p class="mb-4">
                    You may contact your driver directly by clicking the button below. Please note this phone number is only valid during the duration of the delivery.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I want to change or cancel my order
                </div>
                
                <p>
                    You are unable to change or cancel your order as the driver is on their way to pick up your food. Please contact the restaurant for further help.
                </p>
            `,
            droppingOff: `
                <div class="c-delivery-order-help__subtitle">
                    Who is my driver?
                </div>
                
                <p class="mb-4">
                    We work with a number of trusted delivery service partners. The driver delivering your order may not be from the same company where you placed your order. Regardless, your food will still taste just as good!
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I need to contact the driver
                </div>
                
                <p class="mb-4">
                    You may contact your driver directly by clicking the button below. Please note this phone number is only valid during the duration of the delivery.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I want to change or cancel my order
                </div>
                
                <p>
                    You are unable to change or cancel your order as the driver is on their way with your order. Please contact the restaurant for further help.
                </p>
            `,
            delivered: `
                <div class="c-delivery-order-help__subtitle">
                    I haven't received my order
                </div>
                
                <p>
                    Your order may have been left in a safe place by the driver, such as the front porch or doorstep. If your driver took a photo of where they left it, you will see this on the order tracking page.
                </p>

                <p class="mb-4">
                    If you are still unable to find your order, please contact the restaurant for further help.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    There’s a problem with my delivery
                </div>

                <p>
                    Food arrived cold? Missing an item? Received the wrong order? Please contact the restaurant for further help.
                </p>
            `,
            cancelled: `
                <div class="c-delivery-order-help__subtitle">
                    Why was my order canceled?
                </div>

                <p class="mb-4">
                    Please contact the restaurant for more information on why your order has been canceled.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    How can I get a refund for my order?
                </div>

                <p>
                    Please contact the provider where you made your order.
                </p>
            `,
            returned: `
                <div class="c-delivery-order-help__subtitle">
                    Why is my order being returned?
                </div>

                <p>
                    Normally, orders are returned because the driver is unable to contact you and / or find a safe place to leave the order. Some orders are unable to be left as they require an ID check (for example, alcohol delivery). Drivers will make every effort to contact you before they return your order.
                </p>

                <p class="mb-4">
                    If you see your order is being returned, try calling the driver using the button below. You can also contact the restaurant for further help.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    Can my driver return my order to me?
                </div>

                <p>
                    Once a driver has started their return to the restaurant, they are unable to return with your order. Please contact the restaurant for further help.
                </p>
            `,
        },
        poweredBy: `
            Powered by <a class="c-delivery-order__powered-by-link" href="https://curbngo.com/" target="_blank">Curbngo</a>
        `,
        title: 'Curbngo',
        favicon: './assets/white-label/favicons/favicon-curbngo.png'
    },
    'track.localforyou.com': {
        help: {
            default: `
                <div class="c-delivery-order-help__subtitle">
                    What's the status of my order?
                </div>
            
                <p>
                    Your order has been accepted by the restaurant and is currently being prepared. We’ll update the tracking page as soon as a driver has been assigned and an ETA is available.
                </p>
            
                <p class="mb-4">
                    Please note the restaurant is unable to supply further information about the delivery than is displayed here. 
                </p>
            
                <div class="c-delivery-order-help__subtitle">
                    I want to change or cancel my order
                </div>
                
                <p>
                    You are unable to change or cancel your order from this page. Please contact the restaurant for further help.
                </p>`,
            pendingOrRejected: ``,
            pickingUp: `
                <div class="c-delivery-order-help__subtitle">
                    Who is my driver?
                </div>
                
                <p class="mb-4">
                    We work with a number of trusted delivery service partners. The driver delivering your order may not be from the same company where you placed your order. Regardless, your food will still taste just as good!
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I need to contact the driver
                </div>
                
                <p class="mb-4">
                    You may contact your driver directly by clicking the button below. Please note this phone number is only valid during the duration of the delivery.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I want to change or cancel my order
                </div>
                
                <p>
                    You are unable to change or cancel your order as the driver is on their way to pick up your food. Please contact the restaurant for further help.
                </p>
            `,
            droppingOff: `
                <div class="c-delivery-order-help__subtitle">
                    Who is my driver?
                </div>
                
                <p class="mb-4">
                    We work with a number of trusted delivery service partners. The driver delivering your order may not be from the same company where you placed your order. Regardless, your food will still taste just as good!
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I need to contact the driver
                </div>
                
                <p class="mb-4">
                    You may contact your driver directly by clicking the button below. Please note this phone number is only valid during the duration of the delivery.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I want to change or cancel my order
                </div>
                
                <p>
                    You are unable to change or cancel your order as the driver is on their way with your order. Please contact the restaurant for further help.
                </p>
            `,
            delivered: `
                <div class="c-delivery-order-help__subtitle">
                    I haven't received my order
                </div>
                
                <p>
                    Your order may have been left in a safe place by the driver, such as the front porch or doorstep. If your driver took a photo of where they left it, you will see this on the order tracking page.
                </p>

                <p class="mb-4">
                    If you are still unable to find your order, please contact the restaurant for further help.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    There’s a problem with my delivery
                </div>

                <p>
                    Food arrived cold? Missing an item? Received the wrong order? Please contact the restaurant for further help.
                </p>
            `,
            cancelled: `
                <div class="c-delivery-order-help__subtitle">
                    Why was my order canceled?
                </div>

                <p class="mb-4">
                    Please contact the restaurant for more information on why your order has been canceled.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    How can I get a refund for my order?
                </div>

                <p>
                    Please contact the provider where you made your order.
                </p>
            `,
            returned: `
                <div class="c-delivery-order-help__subtitle">
                    Why is my order being returned?
                </div>

                <p>
                    Normally, orders are returned because the driver is unable to contact you and / or find a safe place to leave the order. Some orders are unable to be left as they require an ID check (for example, alcohol delivery). Drivers will make every effort to contact you before they return your order.
                </p>

                <p class="mb-4">
                    If you see your order is being returned, try calling the driver using the button below. You can also contact the restaurant for further help.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    Can my driver return my order to me?
                </div>

                <p>
                    Once a driver has started their return to the restaurant, they are unable to return with your order. Please contact the restaurant for further help.
                </p>
            `,
        },
        poweredBy: `
            Powered by <a class="c-delivery-order__powered-by-link" href="https://localforyou.com/" target="_blank">LocalForYou</a>
        `,
        title: 'LocalForYou'
    },
    'track.foodninja.com.au': {
        help: {
            default: `
                <div class="c-delivery-order-help__subtitle">
                    What's the status of my order?
                </div>
            
                <p>
                    Your order has been accepted by the restaurant and is currently being prepared. We’ll update the tracking page as soon as a driver has been assigned and an ETA is available.
                </p>
            
                <p class="mb-4">
                    Please note the restaurant is unable to supply further information about the delivery than is displayed here. 
                </p>
            
                <div class="c-delivery-order-help__subtitle">
                    I want to change or cancel my order
                </div>
                
                <p>
                    You are unable to change or cancel your order from this page. Please contact the restaurant for further help.
                </p>`,
            pendingOrRejected: ``,
            pickingUp: `
                <div class="c-delivery-order-help__subtitle">
                    Who is my driver?
                </div>
                
                <p class="mb-4">
                    We work with a number of trusted delivery service partners. The driver delivering your order may not be from the same company where you placed your order. Regardless, your food will still taste just as good!
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I need to contact the driver
                </div>
                
                <p class="mb-4">
                    You may contact your driver directly by clicking the button below. Please note this phone number is only valid during the duration of the delivery.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I want to change or cancel my order
                </div>
                
                <p>
                    You are unable to change or cancel your order as the driver is on their way to pick up your food. Please contact the restaurant for further help.
                </p>
            `,
            droppingOff: `
                <div class="c-delivery-order-help__subtitle">
                    Who is my driver?
                </div>
                
                <p class="mb-4">
                    We work with a number of trusted delivery service partners. The driver delivering your order may not be from the same company where you placed your order. Regardless, your food will still taste just as good!
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I need to contact the driver
                </div>
                
                <p class="mb-4">
                    You may contact your driver directly by clicking the button below. Please note this phone number is only valid during the duration of the delivery.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    I want to change or cancel my order
                </div>
                
                <p>
                    You are unable to change or cancel your order as the driver is on their way with your order. Please reach out for assistance by calling or texting <a class="c-delivery-order__powered-by-link" href="tel:+61430344364" target="_blank">0430 344 364</a>
                </p>
            `,
            delivered: `
                <div class="c-delivery-order-help__subtitle">
                    I haven't received my order
                </div>
                
                <p>
                    Your order may have been left in a safe place by the driver, such as the front porch or doorstep. If your driver took a photo of where they left it, you will see this on the order tracking page.
                </p>

                <p class="mb-4">
                    If you are still unable to find your order, please reach out for assistance by calling or texting <a class="c-delivery-order__powered-by-link" href="tel:+61430344364" target="_blank">0430 344 364</a>

                </p>

                <div class="c-delivery-order-help__subtitle">
                    There’s a problem with my delivery
                </div>

                <p>
                    Food arrived cold? Missing an item? Received the wrong order? Please contact the restaurant for further help.
                </p>
            `,
            cancelled: `
                <div class="c-delivery-order-help__subtitle">
                    Why was my order canceled?
                </div>

                <p class="mb-4">
                    Please reach out for assistance by calling or texting <a class="c-delivery-order__powered-by-link" href="tel:+61430344364" target="_blank">0430 344 364</a>
                </p>

                <div class="c-delivery-order-help__subtitle">
                    How can I get a refund for my order?
                </div>

                <p>
                    Please contact the provider where you made your order.
                </p>
            `,
            returned: `
                <div class="c-delivery-order-help__subtitle">
                    Why is my order being returned?
                </div>

                <p>
                    Normally, orders are returned because the driver is unable to contact you and / or find a safe place to leave the order. Some orders are unable to be left as they require an ID check (for example, alcohol delivery). Drivers will make every effort to contact you before they return your order.
                </p>

                <p class="mb-4">
                    If you see your order is being returned, try calling the driver using the button below. You can also contact the restaurant for further help.
                </p>

                <div class="c-delivery-order-help__subtitle">
                    Can my driver return my order to me?
                </div>

                <p>
                    Once a driver has started their return to the restaurant, they are unable to return with your order. Please contact the restaurant for further help.
                </p>
            `,
        },
        poweredBy: `
                Powered by FoodNinja. For any inquiries, please select the "Need help?" button or reach out for assistance by calling or texting <a class="c-delivery-order__powered-by-link" href="tel:+61430344364" target="_blank">0430 344 364</a>
        `,
        title: 'FoodNinja',
        favicon: './assets/white-label/favicons/favicon-foodninja.png'
    }
}
