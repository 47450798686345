export interface IAppRouterState {
    name?: string;
    url: string;
    params: any;
    queryParams?: any;
    fromState: {
      url: string;
      name?: string;
      params: any;
    };
  }
  
  export const initialState: IAppRouterState = {
    name: null,
    url: null,
    params: null,
    queryParams: null,
    fromState: {
      url: null,
      name: null,
      params: null
    }
  };
  
  /**
   * Init state method passed into the store feature module.
   */
  export function initialRouterState() {
    return { ...initialState };
  }