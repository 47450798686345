import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientService } from '../http-client.service';

@Injectable({ providedIn: 'root' })
export class DispatchDataService extends HttpClientService {
  
  private urls = {
    deliveryTracking: '/deliveries/tracking/order/:id',
    deliveryTrackingJs: '/deliveries/tracking/js?id='
  }

  fetchDispatchOrder(deliveryOrderId: string): Observable<any> {
    return this.get(this.urls.deliveryTracking.replace(':id', deliveryOrderId));
  }

  getDeliveryTrackingJs() {
    return this.urls.deliveryTrackingJs;
  }
}