import { createAction, props } from '@ngrx/store';
import {
  TRACKING_DELIVERY_ORDER_FETCH,
  TRACKING_DELIVERY_ORDER_FETCHING_SET,
  TRACKING_DELIVERY_ORDER_SET,
  TRACKING_DELIVERY_ORDER_VIEWED_SET,
  TRACKING_DELIVERY_ORDER_INVALID_SET
} from '../action-types';
import { DeliveryOrder } from '@core/models/dispatch/delivery-order';

export const FetchDeliveryOrderAction = createAction(TRACKING_DELIVERY_ORDER_FETCH);
export const SetDeliveryOrderAction = createAction(TRACKING_DELIVERY_ORDER_SET, props<{ payload: { deliveryOrder: DeliveryOrder }}>());
export const SetViewedDeliveryOrderIdAction = createAction(TRACKING_DELIVERY_ORDER_VIEWED_SET, props<{ payload: { deliveryOrderId: string }}>());
export const SetIsFetchingDeliveryOrderAction = createAction(TRACKING_DELIVERY_ORDER_FETCHING_SET, props<{ payload: { value: boolean }}>());
export const SetIsInvalidDeliveryOrderAction = createAction(TRACKING_DELIVERY_ORDER_INVALID_SET, props<{ payload: { isInvalid: boolean }}>());
