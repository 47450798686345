import { Injectable } from '@angular/core';
import { WindowRef } from './window.component';

@Injectable({ providedIn: 'root' })
export class WindowService {

  private nativeWindow: Window;
  
  constructor(private windowRef: WindowRef) {
    this.nativeWindow = windowRef.nativeWindow;
  }

  isDarkMode() {
    if (this.nativeWindow.matchMedia && this.nativeWindow.matchMedia('(prefers-color-scheme: dark)').matches) {
      // dark mode
      return true;

    } else {

      return false;
    }
  }
}