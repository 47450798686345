import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { WindowService } from 'src/vendor/window/window.service';
import { WindowRef } from 'src/vendor/window/window.component';
import { environment } from '@environments/environment';
import { DispatchDataService } from '@core/data/api/dispatch/dispatch-data.service';
import { DOCUMENT } from '@angular/common';
import { WHITE_LABEL_CONTENT } from 'src/white-labelling/content';
import { IWhiteLabelContentSchema } from 'src/white-labelling/content.schema';

@Component({
  selector: 'ihd-app',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.scss',
    '../styles/index.scss'
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'c-ihd-app',
    '[class.c-ihd-app--dark]': 'isDarkMode'
  }
})
export class AppComponent implements AfterViewInit {

  private readonly URL_PARAM_ID_KEY: string = 'id';
  public isDarkMode: boolean;

  private isWhiteLabel: boolean
  private nativeWindow: Window;

  constructor(private windowService: WindowService,
              private renderer: Renderer2,
              private dispatchDataService: DispatchDataService,
              @Inject(DOCUMENT) private document: Document,
              private windowRef: WindowRef) {
    this.nativeWindow = windowRef.nativeWindow; 
    this.isDarkMode = this.windowService.isDarkMode();
    this.isWhiteLabel = !!environment?.whiteLabel?.length;
  }

  ngAfterViewInit() {
    this.injectAdrollTracking();

    if (this.isWhiteLabel) {
      this.amendIndexHtml();
    }
  }

  private injectAdrollTracking() {
    const script = this.renderer.createElement('script');
    const queryString = this.nativeWindow.location.search;
    const urlParams = new URLSearchParams(queryString);
    script.type = 'text/javascript';
    script.src = `${ environment.api.endpoint }${ this.dispatchDataService.getDeliveryTrackingJs() }${ urlParams.get(this.URL_PARAM_ID_KEY) }`;
    this.renderer.appendChild(this.nativeWindow.document.body, script);
  }

  private amendIndexHtml() {

    const whiteLabelContent: IWhiteLabelContentSchema = WHITE_LABEL_CONTENT[environment.whiteLabel];

    if (whiteLabelContent.favicon?.length) {
      this.document.getElementById('deliveryTrackingFavicon')?.setAttribute('href', whiteLabelContent.favicon);
    }

    if (whiteLabelContent.title?.length) {
      const title = this.document.getElementById('deliveryTrackingTitle');

      if (title !== null) {
        title.innerHTML = whiteLabelContent.title
      }
    }
  }
}
