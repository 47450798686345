// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { BACKEND_URLS } from '../config/backends';

export const environment = {
  production: true,
  settings: {
    url: 'https://track.curbngo.com/',
    localStoragePrefix: 'ihd_local_storage',
    pollingFrequency: 15000
  },
  api: {
    // Options: PROD, DEMO, PG-1... PG-6
    endpoint: BACKEND_URLS['PROD']
  },
  default: {
    support: 'https://www.inhousedelivery.com/for-customers',
  },
  whiteLabel: 'track.curbngo.com'
};