import { InjectionToken, Injectable } from '@angular/core';
import { getWindow } from './window';

export const WINDOW: InjectionToken<any> = new InjectionToken('window');

@Injectable()
export class WindowRef {
    get nativeWindow(): any {
        return getWindow();
    }
}

export const WINDOW_PROVIDERS = [
    { provide: WindowRef, useClass: WindowRef },
    { provide: WINDOW, useFactory: getWindow, deps: [] }
];
