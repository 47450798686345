import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Router } from '@angular/router';
import { ROUTER_GO } from '../action-types';
import { RouterGoAction } from './router-actions';
import { tap } from 'rxjs/operators';

@Injectable()
export class RouterEffects {

  constructor(private router: Router,
              private actions$: Actions) {
  }

  routerGo$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType<RouterGoAction>(ROUTER_GO),
        tap((action: RouterGoAction) => {
          const params: string = action.payload.params;
          const keys: string[] = Object.keys(params || {});

          if (action.payload.url) {

            const url: string = action.payload.url;
            let processedUrl: string = url;

            if (params) {
              if (keys?.length) {
                keys.forEach((key: string) => {
                  if (params[key]) {
                    // replace route param (THIS IS NOT QUERY PARAM!)
                    processedUrl = processedUrl.replace(':' + key, params[key]);
                  }
                });
              }
            }

            // IMPROVEMENT:A: Validate state (Features are allowed by account type, etc)

            this.router.navigateByUrl(processedUrl);

          } else if (action.payload.path) {
            const path: any[] = action.payload.path.split('/')
              .filter((item: string) => item && item.length > 0)
              .map((item: string) => {

                if (item.indexOf(':') === 0) {
                  // This is a param, needs to be replaced
                  if (params[item.substr(1)]) {
                    return params[item.substr(1)];
                  } else {
                    throw new Error(`Missing route parameter ${ item } for RouterGoAction on route ${ action.payload.path }`);
                    return null;
                  }
                } else {
                  const numberPath: number = Number(item);
                  return isNaN(numberPath) ? item : numberPath;
                }
              });

            this.router.navigate(path);
          }
        })
      );
  }, { dispatch: false });
}
