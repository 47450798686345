import { ActionReducer, Action } from '@ngrx/store';

export function combineReducers(
  reducers: any,
  initialState: any = {}
): ActionReducer<any, Action> {
    const reducerKeys = Object.keys(reducers);
    const finalReducers: any = {};

    // tslint:disable prefer-for-of
    for (let i = 0; i < reducerKeys.length; i++) {
        const key = reducerKeys[i];
        if (typeof reducers[key] === 'function') {
            finalReducers[key] = reducers[key];
        }
    }

    const finalReducerKeys = Object.keys(finalReducers);

    return function combination(state: any, action: Action) {
        state = state === undefined ? initialState : state;
        let hasChanged = false;
        const nextState: any = {};
        for (let i = 0; i < finalReducerKeys.length; i++) {
            const key = finalReducerKeys[i];
            const reducer: any = finalReducers[key];
            const previousStateForKey = state[key];
            const nextStateForKey = reducer(previousStateForKey, action, state.entity);

            nextState[key] = nextStateForKey;
            hasChanged = hasChanged || nextStateForKey !== previousStateForKey;
        }
        return hasChanged ? nextState : state;
    };
}
